html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Red Hat Display, sans-serif;
  font-size: 18px;
}

html a, body a {
  color: #999;
  font-size: 1em;
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.break {
  margin: 80px 0;
}

.container {
  padding: 2% 4%;
}

.row {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.row.align-center {
  align-items: center;
}

.column {
  width: 100%;
}

.column p {
  text-align: justify;
}

.column.left, .column.right {
  width: calc(50% - 10px);
}

.column.left {
  margin-right: 10px;
}

.column.right {
  margin-left: 10px;
}

.column.left img {
  width: 70%;
}

.column b {
  margin-bottom: 10px;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.buy-tickets {
  margin: 10px 0 40px;
  font-size: 1.5em;
  font-weight: bold;
}

.social {
  display: flex;
}

.social .handle {
  font-size: .8em;
  display: block;
}

.social a {
  width: auto;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.social a img {
  max-width: 32px;
  margin-bottom: 20px;
}

.about-me-link {
  text-align: right;
  margin-right: 10px;
}

.about-me-images {
  margin-top: 20px;
}

.column.one-third {
  width: calc(33.3333% - 20px);
}

.column.one-third.padding {
  padding-right: 10px;
}

.column.one-third img {
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
}

.column.one-third img.selected {
  box-shadow: 0 0 30px #000;
}

.about-me h2 {
  margin-top: 0;
}

.projects .row {
  margin-bottom: 5px;
}

.projects .project {
  width: 33.3333%;
  position: relative;
}

.projects .project.pr, .projects .project.pr .title {
  padding-right: 10px;
}

.projects .project:hover .image {
  opacity: .5;
  -webkit-filter: none;
  filter: none;
}

.projects .project:hover .title {
  visibility: visible;
}

.projects .project:hover .title p {
  text-align: center;
}

.projects .project .image {
  width: 100%;
  -webkit-filter: grayscale();
  filter: grayscale();
}

.projects .project .title {
  visibility: hidden;
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.project-description .ytembed {
  width: 100%;
  height: 300px;
  margin: 0;
}

.project-description .column.left {
  margin-left: 0;
}

.project-description .column.left img {
  width: 100%;
}

.project-description .column.right h2 {
  margin-top: 0;
}

.project-description .column.right .subtitle {
  font-size: .8rem;
}

.project-images {
  flex-direction: row;
}

.project-images .column.right img {
  width: 100%;
}

footer .powered-by {
  justify-content: flex-end;
  align-items: center;
  font-size: .55em;
  display: flex;
}

.back-to-top {
  width: 32px;
  height: 32px;
  opacity: .5;
  color: #fff;
  cursor: pointer;
  background-color: #999;
  border: 1px solid #999;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
  position: fixed;
  bottom: 40px;
  right: 20px;
}

.back-to-top span {
  height: 24px;
}

.back-to-top:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .about-me {
    flex-direction: column;
  }

  .about-me .column.right, .about-me .column.left, .about-me .column.left img {
    width: 100%;
  }

  .about-me .about-me-main-image, .about-me h2 {
    margin-top: 20px;
  }

  .about-me-images {
    flex-direction: column;
  }

  .about-me-images .one-third {
    width: 100%;
  }

  .about-me-images .one-third.padding {
    padding-right: 0;
  }

  .main.responsive .column.left {
    margin-left: 0;
  }

  .main.responsive .column.left img {
    width: 100%;
  }

  .project-images .column.right img {
    padding-left: 0;
  }

  .site-title {
    margin-bottom: 0;
  }

  .responsive-hidden {
    display: none;
  }

  .responsive-visible {
    display: block !important;
  }

  .column.left {
    margin-right: 0;
  }

  .column.right {
    margin-left: 0;
  }

  .row.responsive {
    flex-direction: column;
  }

  .row.responsive .left, .row.responsive .right {
    width: 100%;
  }

  .row.responsive .about-me-link {
    text-align: left;
  }

  .projects .project .image {
    opacity: .5;
    -webkit-filter: none;
    filter: none;
  }

  .projects .project .title {
    visibility: visible;
    font-size: .5em;
  }
}

/*# sourceMappingURL=about_me.becaa840.css.map */
