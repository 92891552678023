@import 'normalize.css'

$template-black = #000
$template-grey = #999
$template-white = #fff

html, body
  width 100%
  height 100%
  margin 0
  padding 0

  font-family 'Red Hat Display', sans-serif
  font-size 18px

  a
    font-size 1em
    text-decoration none
    color $template-grey

.hidden
  display none !important

.break
  margin 80px 0

.container
  padding 2% 4%

.row
  display flex
  justify-content space-between
  align-items flex-start

.row.align-center
  align-items center

.column
  width 100%

  p
    text-align justify

.column.left, .column.right
  width calc(50% - 10px)

.column.left
  margin-right 10px

.column.right
  margin-left 10px

.column.left
  img
    width 70%

.column
  b
    margin-bottom 10px

.no-margin-top
  margin-top 0

.no-margin-bottom
  margin-bottom 0

.buy-tickets
  margin 10px 0 40px
  font-size 1.5em
  font-weight bold

.social
  display flex

  .handle
    display block
    font-size 0.8em

  a
    display flex
    flex-direction column
    align-items center
    width auto
    margin-right 20px

    img
      max-width 32px
      margin-bottom 20px

.about-me-link
  margin-right 10px
  text-align right

.about-me-images
  margin-top 20px

.column.one-third
  width calc(33.333333% - 20px)

  &.padding
    padding-right 10px

  img
    display inline-block
    width 100%
    margin-bottom 20px

    &.selected
      box-shadow 0px 0px 30px $template-black

.about-me
  h2
    margin-top 0

.projects
  .row
    margin-bottom 5px

  .project
    position relative
    width 33.333333%

    &.pr
      padding-right 10px

      .title
        padding-right 10px

    &:hover
      .image
        opacity 0.5
        filter none

      .title
        visibility visible

        p
          text-align center

    .image
      width 100%
      filter grayscale(100%)

    .title
      position absolute
      top 0
      right 0
      bottom 0
      left 0

      display flex
      justify-content center
      align-items center

      visibility hidden

      font-size 1em
      font-weight bold
      text-align center

      color $template-black

.project-description
  .ytembed
    width 100%
    height 300px
    margin 0

  .column.left
    margin-left 0

    img
      width 100%

  .column.right
    h2
      margin-top 0

    .subtitle
      font-size 0.8rem

.project-images
  flex-direction row

  .column.right
    img
      width 100%

footer
  .powered-by
    display flex
    justify-content flex-end
    align-items center
    font-size 0.55em

.back-to-top
  position fixed
  right 20px
  bottom 40px

  display flex
  justify-content center
  align-items center

  width 32px
  height 32px

  font-size 2rem

  border 1px solid $template-grey
  border-radius 50%
  opacity 0.5
  color $template-white
  background-color $template-grey
  cursor pointer

  span
    height 24px

.back-to-top:hover
  opacity 1

@media all and (max-width 768px)
  .about-me
    flex-direction column

    .column.right, .column.left, .column.left img
      width 100%

    .about-me-main-image, h2
      margin-top 20px

  .about-me-images
    flex-direction column

    .one-third
      width 100%

      &.padding
        padding-right 0

  .main.responsive
    .column.left
      margin-left 0
      img
        width 100%

  .project-images .column.right img
    padding-left 0

  .site-title
    margin-bottom 0

  .responsive-hidden
    display none

  .responsive-visible
    display block !important

  .column.left
    margin-right 0

  .column.right
    margin-left 0

  .row.responsive
    flex-direction column

    .left, .right
      width 100%

    .about-me-link
      text-align left

  .projects
    .project
      .image
        opacity 0.5
        filter none

      .title
        visibility visible
        font-size 0.5em
